// # Core
import React, { PureComponent } from 'react';
import { objectOf, string } from 'prop-types';

// # Redux
import { connect } from 'react-redux';
import {
	updateTerminalTheme,
	updateTerminalLogo,
	updateTerminalRunline
} from '../../redux/actions';

// # Instruments
import { getUniqueID } from '../../helpers';
import { terminalThemesList } from '../../initialData';
import _ from '../../en';

class SettingsThemeColumn extends PureComponent {
	constructor() {
		super();

		this.state = {
			fileInputValue: ''
		};
	}

	onInputFileChange = event => {
		const { dispatch } = this.props;
		const { files } = event.target;

		this.setState({ fileInputName: event.target.value });
		return dispatch(
			updateTerminalLogo({
				name: files[0].name,
				path: URL.createObjectURL(files[0])
			})
		);
	};

	removeTerminalLogo = () => {
		const { dispatch } = this.props;
		this.setState({ fileInputName: '' });
		return dispatch(updateTerminalLogo());
	};

	clearRunlineInput = () => {
		const { dispatch } = this.props;
		return dispatch(updateTerminalRunline(''));
	};

	updateRunline = event => {
		const { dispatch } = this.props;
		const { value } = event.target;
		return dispatch(updateTerminalRunline(value));
	};

	onThemeColorClick = theme => {
		const { dispatch } = this.props;
		return dispatch(updateTerminalTheme(theme));
	};

	render() {
		const { terminalTheme, terminalLogo, terminalRunline } = this.props;
		const { fileInputValue } = this.state;

		return (
			<div className="settings__column settings__column--theme">
				<div className="settings__title">4. {_.settings}</div>

				<div className="settings__themes">
					<div className="settings__themes-title">{_.color}</div>

					<div className="settings__themes-colors">
						{Object.keys(terminalThemesList).map(theme => (
							<div
								key={getUniqueID()}
								className="settings__themes-color-column"
								onClick={() => this.onThemeColorClick(theme)}>
								<div
									className={`settings__themes-color settings__themes-color--${theme}${
										terminalTheme === theme ? ' is-active' : ''
									}`}
								/>
								<div
									className="settings__themes-color-title"
									dangerouslySetInnerHTML={{
										__html: terminalThemesList[theme].name
									}}
								/>
							</div>
						))}
					</div>
				</div>

				<div className="settings__logo">
					<label className="settings__logo-label">
						{_.uploadLogo}
						<input
							accept=".png, .jpg, .jpeg, .svg"
							hidden
							onChange={this.onInputFileChange}
							type="file"
							value={fileInputValue}
						/>
					</label>

					{terminalLogo.name.length > 0 ? (
						<div className="settings__logo-title">{terminalLogo.name}</div>
					) : null}

					<div
						className={`settings__logo-cross settings__cross${
							terminalLogo.name.length === 0 ? ' is-disabled' : ''
						}`}
						onClick={this.removeTerminalLogo}
					/>
				</div>

				<div className="settings__runline">
					<input
						className="settings__runline-input"
						onChange={this.updateRunline}
						placeholder={_.textOnLedScreen}
						type="text"
						value={terminalRunline}
					/>

					<div
						className={`settings__runline-cross settings__cross${
							terminalRunline.length === 0 ? ' is-disabled' : ''
						}`}
						onClick={this.clearRunlineInput}
					/>
				</div>
			</div>
		);
	}
}

SettingsThemeColumn.propTypes = {
	terminalTheme: string.isRequired,
	terminalLogo: objectOf(string).isRequired,
	terminalRunline: string.isRequired
};

const mapStateToProps = state => {
	const { terminalTheme, terminalLogo, terminalRunline } = state.terminalData;

	return {
		terminalTheme,
		terminalLogo,
		terminalRunline
	};
};

export default connect(mapStateToProps)(SettingsThemeColumn);
