export default {
	location: 'Location',
	businessCenter: 'Business Center',
	shoppingMall: 'Shopping Mall',
	residentialBuilding: 'Residential Building',
	basicSetup: 'Basic setup',
	Base: 'Base',
	Business: 'Business',
	Premium: 'Premium',
	Minimal: 'Base',
	Full: 'Full',
	expand: 'Expand',
	Main: 'Main',
	Post: 'post',
	FreashAndFreeze: 'Fresh&Freeze',
	Laundry: 'Laundry',
	Hot: 'Hot',
	SoftVending: 'Soft Vending',
	FreshVending: 'Fresh Vending',
	settings: 'Settings',
	color: 'Color',
	uploadLogo: 'Upload logo',
	textOnLedScreen: 'Text on LED screen',
	sendConfigToEmail: 'Email my OmniHUB',
	send: 'send',
	enterEmail: 'Enter email',
	enterYourPhone: 'Enter your phone number',
	fillInForm: 'Please fill in the form',
	phoneNumberError: 'Phone number is incorrect',
	emailError: 'E-mail is incorrect',
	logo: 'logo',
	noLogo: 'no logo',
	receivedOmniHub:
		'We received your OmniHub setup. You will get an e-mail with your setup soon.',
	buildUpHub: 'Build up your OmniHub',
	perfectProduct:
		'Create the product that will be a perfect match for your business',
	contactManager: 'I would like to contact manager to get more insights',
	whatIsYourName: ' What is your name?',
	confirmAccept: ' I confirm personal data processing',
	confirmPersonalData:
		'Please confirm personal data processing to receive an e-mail with your OmniHub setup'
};
