// # Core
import React, { PureComponent } from 'react';

// # Components
import SettingsStack from './SettingsStack';

// # Instruments
import { stacksConfigurations } from '../../initialData';
import { getUniqueID } from '../../helpers';
import _ from '../../en';

class SettingsStacksColumn extends PureComponent {
	render() {
		return (
			<div className="settings__column settings__column--stacks">
				<div className="settings__title">3. {_.expand}</div>

				<div className="settings__stacks">
					{Object.keys(stacksConfigurations).map(stackType => (
						<SettingsStack key={getUniqueID()} stackType={stackType} />
					))}
				</div>
			</div>
		);
	}
}

export default SettingsStacksColumn;
