// # Core
import React, { Component } from 'react';
import axios from 'axios';

// # Redux
import { connect } from 'react-redux';
import { updatePopupVisibility } from '../../redux/actions';

// # Instruments
import {
	locationsList,
	complectationsList,
	terminalThemesList,
	stacksConfigurations
} from '../../initialData.js';
import _ from '../../en';

class Popup extends Component {
	constructor() {
		super();

		this.state = {
			name: '',
			email: '',
			phone: '',
			isCheckedRecall: false,
			isCheckedAgreement: false,
			nameInputError: '',
			emailInputError: '',
			phoneInputError: '',
			formError: '',
			successMessage: ''
		};
	}

	closePopup = () => {
		const { dispatch } = this.props;
		return dispatch(updatePopupVisibility(false));
	};

	onHandleInputChange = (event, inputName, inputErrorName) => {
		const { value } = event.target;
		return this.setState({ [inputName]: value });
	};

	onHandleRecallChange = () => {
		return this.setState({ isCheckedRecall: !this.state.isCheckedRecall });
	};

	onHandleAgreementChange = () => {
		return this.setState({
			isCheckedAgreement: !this.state.isCheckedAgreement
		});
	};

	isFormValid = () => {
		const {
			name,
			email,
			phone,
			isCheckedRecall,
			isCheckedAgreement
		} = this.state;

		let newState = {
			nameInputError: '',
			emailInputError: '',
			phoneInputError: '',
			formError: '',
			successMessage: ''
		};

		let isValid = true;

		if (name.length === 0) {
			newState.nameInputError = _.fillInForm;
			isValid *= false;
		}

		if (email.length === 0) {
			newState.emailInputError = _.fillInForm;
			isValid *= false;
		} else {
			const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			if (!emailRegExp.test(String(email).toLowerCase())) {
				newState.emailInputError = _.emailError;
				isValid *= false;
			}
		}

		if (phone.length === 0) {
			if (isCheckedRecall) {
				newState.phoneInputError = _.fillInForm;
				isValid *= false;
			}
		} else {
			if (phone.trim().length < 8) {
				newState.phoneInputError = _.phoneNumberError;
				isValid *= false;
			}
		}

		if (!isCheckedAgreement) {
			newState.formError = _.confirmPersonalData;
			isValid *= false;
		}

		this.setState({ ...this.state, ...newState });
		return isValid;
	};

	onSubmitClick = async () => {
		const { name, email, phone, isCheckedRecall } = this.state;
		const {
			terminalStacksList,
			terminalLocationId,
			terminalComplectationId,
			terminalTheme,
			terminalRunline,
			terminalLogo
		} = this.props;

		if (this.isFormValid()) {
			return await axios
				.post(
					`http://logs-01.loggly.com/inputs/${atob(
						'NTc4ODA3NWMtOWI0OS00ZDlhLWJmZjQtNWQ0MDJlOThjM2Vl'
					)}/tag/http/`,
					{
						userName: name, // Имя юзера
						userEmail: email, // Имейл юзера
						userPhoneNumber: phone, // Телефон юзераа
						userWaitForConsultantCall: isCheckedRecall, // Ожидает связи с оператором
						terminalLocation: locationsList[terminalLocationId].title, // Локация для установки
						terminalComplectation:
							complectationsList[terminalComplectationId].name, // Комплектация (название)
						terminalConfigurationInOrder: terminalStacksList.map(
							stack => stacksConfigurations[stack.type].title
						), // Комплектация (стеки по порядку)
						terminalTheme: terminalThemesList[terminalTheme].title, // Наименование темы почтомата
						terminalRunline, // Текст бегущей строки
						terminalLogo: terminalLogo.name ? _.logo : _.noLogo // Наличие логотипа
					}
				)
				.then(res => {
					const { response } = res.data;

					if (response) {
						return this.setState({
							name: '',
							email: '',
							phone: '',
							isCheckedRecall: false,
							isCheckedAgreement: false,
							nameInputError: '',
							emailInputError: '',
							phoneInputError: '',
							formError: '',
							successMessage: _.receivedOmniHub
						});
					}
				});
		}
	};

	render() {
		const {
			name,
			email,
			phone,
			formError,
			successMessage,
			nameInputError,
			emailInputError,
			phoneInputError,
			isCheckedRecall,
			isCheckedAgreement
		} = this.state;

		return (
			<div className="popup">
				<div className="popup__exit" onClick={this.closePopup} />
				<div className="popup__window">
					<div className="popup__cross" onClick={this.closePopup} />

					<div className="popup__title">{_.sendConfigToEmail}</div>

					<label className="popup__input-container">
						<span className="popup__input-title">{_.whatIsYourName}</span>
						<input
							className="popup__input"
							type="text"
							value={name}
							onChange={event => this.onHandleInputChange(event, 'name')}
						/>
						{nameInputError.length > 0 ? (
							<span className="popup__input-error">{nameInputError}</span>
						) : null}
					</label>

					<label className="popup__input-container">
						<span className="popup__input-title">{_.enterEmail}</span>
						<input
							className="popup__input"
							type="email"
							value={email}
							onChange={event => this.onHandleInputChange(event, 'email')}
						/>

						{emailInputError.length > 0 ? (
							<span className="popup__input-error">{emailInputError}</span>
						) : null}
					</label>

					<label className="popup__input-container">
						<span className="popup__input-title">{_.enterYourPhone}</span>
						<input
							className="popup__input"
							type="tel"
							value={phone}
							onChange={event => this.onHandleInputChange(event, 'phone')}
						/>

						{phoneInputError.length > 0 ? (
							<span className="popup__input-error">{phoneInputError}</span>
						) : null}
					</label>

					<div className="popup__checkboxes">
						<label className="popup__checkbox">
							<input
								type="checkbox"
								value={isCheckedRecall}
								checked={isCheckedRecall}
								onChange={this.onHandleRecallChange}
							/>
							<span className="popup__checkbox-title">{_.contactManager}</span>
						</label>

						<label className="popup__checkbox">
							<input
								type="checkbox"
								value={isCheckedAgreement}
								checked={isCheckedAgreement}
								onChange={this.onHandleAgreementChange}
							/>
							<span className="popup__checkbox-title">{_.confirmAccept}</span>
						</label>
					</div>

					<div className="popup__button" onClick={this.onSubmitClick}>
						{_.send}
					</div>

					{formError.length > 0 ? (
						<div className="popup__error">{formError}</div>
					) : null}

					{successMessage.length > 0 ? (
						<div className="popup__success">{successMessage}</div>
					) : null}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const {
		isVisiblePopup,
		terminalStacksList,
		terminalLocationId,
		terminalComplectationId,
		terminalTheme,
		terminalRunline,
		terminalLogo
	} = state.terminalData;

	return {
		isVisiblePopup,
		terminalStacksList,
		terminalLocationId,
		terminalComplectationId,
		terminalTheme,
		terminalRunline,
		terminalLogo
	};
};

export default connect(mapStateToProps)(Popup);
