// # Core
import React, { PureComponent } from 'react';
import { string } from 'prop-types';

// # Redux
import { connect } from 'react-redux';
import {
	updateTerminalStacksList,
	updateTerminalComplectationId
} from '../../redux/actions';

// # Instruments
import { getUniqueID } from '../../helpers';
import { complectationsList } from '../../initialData';
import _ from '../../en';

class SettingsComplectationColumn extends PureComponent {
	updateComplectationId = id => {
		const { dispatch } = this.props;

		if (complectationsList[id].complectation) {
			dispatch(updateTerminalStacksList(complectationsList[id].complectation));
		}

		return dispatch(updateTerminalComplectationId(id));
	};

	render() {
		const { terminalComplectationId } = this.props;

		return (
			<div className="settings__column settings__column--complectation">
				<div className="settings__title">2. {_.basicSetup}</div>

				<div className="settings__options">
					{Object.keys(complectationsList).map(complectationKey => {
						const { id, name } = complectationsList[complectationKey];

						return (
							<div
								key={getUniqueID()}
								className={`settings__option${
									terminalComplectationId === id ? ' is-active' : ''
								}`}
								onClick={() => this.updateComplectationId(id)}>
								<div className="settings__option-circle"></div>
								<div className="settings__option-title">{name}</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

SettingsComplectationColumn.propTypes = {
	terminalComplectationId: string.isRequired
};

const mapStateToProps = state => {
	const { terminalComplectationId } = state.terminalData;

	return {
		terminalComplectationId
	};
};

export default connect(mapStateToProps)(SettingsComplectationColumn);
